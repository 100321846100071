import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/styles/border.css'
import '@/assets/styles/reset.css'
import '@/assets/styles/scrollbar.css'; // 根据你的文件路径调整

import '@/router/permission.js'
// 国际化中文
import zhCn from 'element-plus/es/locale/lang/zh-cn'
//自定义图标
import SvgIcon from '@/icons'
import {buttonAuthority} from "@/sys/mixin";
// 富文本编辑器
import {QuillEditor,Quill} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {container,QuillWatch,ImageExtend} from "quill-image-extend-module";
// 裁剪组件
import VueCropper from 'vue-cropper';
import 'vue-cropper/dist/index.css'
import './assets/styles/color.scss'; // 路径应与您的文件结构相匹配
import VueLazyload from 'vue-lazyload'

import SearchBar from '/src/components/searchBar'
import TableAEDialog from '/src/components/tableAEDialog'
import TableMenu from '/src/components/tableMenu'
import TableAction from '/src/components/tableAction'
import TableColumn from '/src/components/tableColumn'
import CropperDialog from '/src/components/cropperDialog'
import CropperEditorDialog from '/src/components/cropperEditorDialog'
import ImageView from '/src/components/imageView'


Quill.register('modules/ImageExtend', ImageExtend)
let BlockEmbed = Quill.imports['blots/embed'];
// 自定义img标签
class ImageBlot extends BlockEmbed {
    static create (value) {
        let node = super.create();
        node.setAttribute('src', value.src);
        node.setAttribute('width', value.width);
        return node;
    }
    static value (node) {
        return {
            src: node.getAttribute('src'),
            width: node.getAttribute('width')
        }
    }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
Quill.register(ImageBlot)

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 首页登陆W/H壁纸
import LoginImageW from '../src/assets/img/LoginImageW.jpg';  // 黑色
global.LoginImageW = LoginImageW;
import LoginImageH from '../src/assets/img/LoginImageH.jpg';
global.LoginImageH = LoginImageH;

// 首页 W/H
// Web
import indexImageWW from '../src/assets/img/indexImageWW.jpg';
global.indexImageWW = indexImageWW;
import indexImageWB from '../src/assets/img/indexImageWB.jpg';
global.indexImageWB = indexImageWB;
// Mobel
import indexImageHW from '../src/assets/img/indexImageHW.jpg';
global.indexImageHW = indexImageHW;
import indexImageHB from '../src/assets/img/indexImageHB.jpg';
global.indexImageHB = indexImageHB;

// 注册W/H
import registerImageW from '../src/assets/img/registerImageW.jpg';
global.registerImageW = registerImageW;
import registerImageH from '../src/assets/img/registerImageH.jpg';
global.registerImageH = registerImageH;

// 忘记密码W/H
import resetPasswordImageW from '../src/assets/img/GEMandJJ.jpg';
global.resetPasswordImageW = resetPasswordImageW;
import resetPasswordImageH from '../src/assets/img/GEM-H.jpg';
global.resetPasswordImageH = resetPasswordImageH;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// 进入vip页面的凭证
global.cvAuthorityValue = false;

const app = createApp(App)
SvgIcon(app);
app.mixin(buttonAuthority)
app.use(ElementPlus, {locale: zhCn,})
app.use(store)
app.use(SvgIcon)
app.use(router)
app.use(ElementPlus)
app.use(VueCropper)
app.use(VueLazyload, {})
// 富文本编辑器
app.component('QuillEditor', QuillEditor)
app.component('SearchBar', SearchBar)
app.component('TableMenu', TableMenu)
app.component('TableAEDialog', TableAEDialog)
app.component('TableAction', TableAction)
app.component('TableColumn', TableColumn)
app.component('CropperDialog', CropperDialog)
app.component('CropperEditorDialog', CropperEditorDialog)
app.component('ImageView', ImageView)
app.mount('#app')


// 页面方法缩小报错 ResizeObserver loop limit exceeded
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

