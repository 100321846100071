import router from "@/router/index"
import store from "@/store"
import {inject, nextTick, provide, ref} from "vue";
import {getServerUrl} from "@/util/request";
import {createRouter, createWebHashHistory} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import {decryptData} from "@/util/decryptData";

/**
 * 路由跳转前鉴权
 */
router.beforeEach(async (to, from, next) => {
    // console.log("to.path")
    const whiteList = ['/login', '/register', '/resetPassword', '/' ,'/sleep'] // 白名单
    const resetRouterByReload = ['/index'] // 退出后因刷新不显示错误信息的白名单
    let token = null
    if (sessionStorage.getItem("token") == "" || sessionStorage.getItem("token") == null) {
        // next({path: '/'})
    } else {
        token = await decryptData(sessionStorage.getItem("token"));
    }
    // console.log(from.path)
    // console.log(to.path)
    // 跳转前认证token
    if (token && to.path != '/resetPassword') {
        // console.log("存在token")
        // 判断手动从sleep调出来consol
        // console.log(from.path)
        // console.log(to.path)
        if (from.path == '/sleep') {
            let systemAuthorityString = JSON.parse(await decryptData(sessionStorage.getItem("systemAuthority")))
            // console.log("判断手动从sleep调出来")
            // console.log(systemAuthorityString.sleepAuthority)
            if (systemAuthorityString.sleepAuthority === true) {
                alert('请先解锁屏幕');
                next({path: '/sleep'})
            }
        }
        let hasRoutes = store.getters.GET_ROUTES_STATE;
        if (!hasRoutes) {
            let menuList = JSON.parse(await decryptData(sessionStorage.getItem("menuList")))
            // 刷新后store清空 配置路由 每次刷新绑定一次
            if (menuList == null || menuList == "") {
                initErrorToClear()
                next({path: '/'})
            } else {
                bindRoute(menuList);
                store.commit("SET_MENULIST", sessionStorage.getItem("menuList"));
            }
            // 重点 next({ ...to, replace: true })
            // 如果 bindRoute 中的 addRoute 并未完成，路由守卫会一层一层的执行执行，直到 addRoutes 完成，找到对应的路由
            next({...to, replace: true})
            store.commit("SET_ROUTES_STATE", true); //设置绑定路由状态 这种方式 只能提交一次 第二次就一直true
        }
        // 刷新页面更新 权限
        if (sessionStorage.getItem("userAuthority") == null || sessionStorage.getItem("userAuthority") == "") {
            initErrorToClear()
            next({path: '/'})
        } else store.commit("SET_USERAUTHORITY", sessionStorage.getItem("userAuthority"))
        // 刷新页面更新 用户信息
        if (sessionStorage.getItem("userInfo") == null || sessionStorage.getItem("userInfo") == "") {
            initErrorToClear()
            next({path: '/'})
        } else store.commit("SET_USERINFO", sessionStorage.getItem("userInfo"))
        // 刷新页面更新 系统权限
        if (sessionStorage.getItem("systemAuthority") == null || sessionStorage.getItem("systemAuthority") == "") {
            initErrorToClear()
            next({path: '/'})
        } else store.commit("SET_SYSTEMAUTHORITY", sessionStorage.getItem("systemAuthority"))
        // 登录过就不能访问登录界面，需要中断这一次路由守卫，执行下一次路由守卫，并且下一次守卫的to是主页
        if (to.path === '/login') {
            next({path: '/'})
        }
        // 已有权限阻止手动前往vip页面
        if (to.path === '/vip' && store.state.systemAuthority.cvAuthority) {
            next({path: '/'})
        }
        next();
    } else {
        console.log(from.path)
        console.log(to.path)
        // console.log("没token")
        // token不在或过期 但跳转的是登陆/注册/忘记密码 页面
        if (whiteList.includes(to.path)) {
            next();
        } else {
            /**
             * 退出后刷新 后默认回到 / -> index -> login
             * index.js 中将 / 登陆后重定向为 /index ,后-> /login
             */
            // 重定向
            if (from.path == '/sleep') {
                sleepErrorToClear()
            }
            next("/login")
        }
    }
})

const initErrorToClear = () => {
    ElMessage.error("请不要篡改数据!")
    store.commit('SET_SESSION_TOKEN', ""); // token双端同时制空
    store.commit('SET_USERAUTHORITY', "");  // 用户权限双端同时制空
    store.commit('SET_MENULIST', "");  // 用户菜单双端同时制空
    store.commit('SET_USERINFO', {});  // 用户信息双端同时制空
    store.commit('SET_ROUTES_STATE', false);  // 关闭路由
    store.commit('RESET_TABS'); // 回归导航栏
}

const sleepErrorToClear = () => {
    ElMessage.error("请登陆后再操作")
    window.sessionStorage.clear()
    store.commit('SET_ROUTES_STATE', false);  // 关闭路由
    store.commit('RESET_TABS'); // 回归导航栏
}

// 动态绑定路由
const bindRoute = (menuList) => {
    let nowRoutes = router.options.routes; //获取路由里面的routes  复制一份
    // console.log(nowRoutes)
    menuList.forEach(menu => {
        if (menu.children) {
            menu.children.forEach(m => {
                let route = menuToRoute(m, menu.name);// 菜单对象转成路由对象 封装好新的路由对象
                if (route) {
                    nowRoutes[0].children.push(route);
                }
            })
        }
    })
    // 重新添加到路由
    nowRoutes.forEach(route => {
        router.addRoute(route)
    })
}

// 菜单对象转成路由对象
const menuToRoute = (menu, parentName) => {
    if (!menu.component) {
        return null;
    } else {
        // 封装路由的信息包括 名字 路径 组件路径 父类名称
        let route = {
            name: menu.name,
            path: menu.path,
            component: () => import('@/views/' + menu.component + '.vue'),
            meta: {
                parentName: parentName
            },
        }
        return route;
    }
}
