import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: '导航',
        component: () => import('../layout'),
        redirect: '/index',
        children:
            [
                {
                    path: '/index',
                    name: '首页',
                    component: () => import('../views/systemIndex/index'),
                },
            ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/register.vue')
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import('../views/resetPassword.vue')
    },
    {
        path: '/vip',
        name: 'vip',
        component: () => import('../views/staticPage/vip/index.vue')
    },
    {
        path: '/sleep',
        name: 'sleep',
        component: () => import('../views/staticPage/sleep/index.vue')
    },
    // 添加404 Not Found 路由
    // {
    //     path: '/:catchAll(.*)',
    //     name: '404',
    //     component: () => import('../views/staticPage/NotFound.vue')
    // },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
