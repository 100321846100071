import SvgIcon from '@/components/SvgIcon'
// require.context 方法导入了一个 SVG 文件夹中的所有 SVG 图标
// 使用正则表达式来匹配需要导入的模块文件名，这里使用了 ./svg 目录下所有以 .svg 结尾的文件。
const svgRequired = require.context('./svg', false, /\.svg$/)
svgRequired.keys().forEach(item => {
    svgRequired(item)
})

export default (app) => {
    app.component('svg-icon', SvgIcon)
}
