<template>
  <el-dialog :title="dialogTitle" width="90%" center @close="handleClose"
             style="height: 85%" custom-class="avatarView"
  >
    <div class="avatarContainer"
         :style="{'width': imageWidth + 'px !important','height': imageHeight + 'px !important'}"
    >
      <el-image  :src="props.imageKey[props.imageIndex]" class="avatarImg" disabled fit="contain"
      >
      </el-image>
    </div>
  </el-dialog>
</template>

<script setup>

import {defineEmits, defineProps, ref, watch, onMounted, reactive, nextTick, watchEffect, computed} from "vue"
import requestUtil, {getImageUrl,getServerUrl,getVideoUrl,getTemVideoUrl,getTemImageUrl} from "@/util/request";
import {ElMessage} from 'element-plus'
import store from "@/store";

const props = defineProps(
    {
      id: {
        type: Number,
        default: -1,
        required: true
      },
      imageIndex: {
        type: Number,
        default: 0,
        required: true
      },
      dialogTitle: {
        type: String,
        default: '',
        required: true
      },
      dialogVisible: {
        type: Boolean,
        default: false,
        required: true
      },
      elDialogWidth: {
        type: String,
        default: '70%',
        required: true
      },
      imageKey: {
        type: String,
        default: 'default.jpg',
        required: true
      }
    }
)

watch(
    () => [props.dialogVisible],
    () => {
      if (props.dialogVisible == true) {
        setDialogWidth()
      }
    }
)
const imageWidth = ref(200)
const imageHeight = ref(200)
// 根据当前dialog的最低/高度/宽度选择作为展示图片的max
const setDialogWidth = () => {
  imageWidth.value = window.innerWidth > window.innerHeight ? window.innerHeight*0.75 : window.innerWidth*0.75
  imageHeight.value = window.innerWidth > window.innerHeight ? window.innerHeight*0.75 : window.innerWidth*0.75
}
</script>

<style lang="scss">
.avatarView{
    .el-dialog__header {
      padding: 5px;
    }
    .el-dialog__body{
      padding: 5px !important;
    }
}
.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:  auto !important;
}

.avatarImg {
  width: 100%;
  height: 100%;
}

</style>
