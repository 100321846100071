import {createStore} from 'vuex'
import router from "@/router";
import {encrypt, decrypt} from "@/util/jsencrypt";
import {decryptData, encryptData} from "@/util/decryptData";

export default createStore({
    state: {
        hasRoutes: false,  // 没登陆默认没有有路由
        token: null,
        userAuthority: "",
        menuList: "",
        systemAuthority: '',
        userInfo: {},
        editableTabsValue: '/index',  // 默认选中顶部tab路径
        theme: "white",
        sleepAuthority: false,
        // 默认动态tab的值
        editableTabs: [{title: '首页', name: '/index',}]
    },
    getters: {
        GET_TOKEN: async state => {
            return JSON.parse(await decryptData(sessionStorage.getItem("token")))
            // return state.token
        },
        GET_THEME: state => {
            return sessionStorage.getItem("theme")
        },
        // GET_USERAUTHORITY:async state => {
        //     return state.userAuthority
        // },
        // GET_MENULIST: async state => {
        //     return JSON.parse(await decryptData(sessionStorage.getItem("menuList")))
        // },
        // GET_USERINFO: async state => {
        //     return JSON.parse(await decryptData(sessionStorage.getItem("userInfo")))
        // },
        // GET_CVAUTHORITY: state => {
        //     return sessionStorage.getItem("cvAuthority")
        // },
        // GET_SLEEPAUTHORITY: state => {
        //     return sessionStorage.getItem("sleepAuthority")
        // },
        GET_ROUTES_STATE: state => {
            return state.hasRoutes
        },
    },
    mutations: {
        // 存入session直接存加密的
        SET_ROUTES_STATE: (state, RoutesStatus) => {
            state.hasRoutes = RoutesStatus  // 设置有路由
        },
        SET_SESSION_TOKEN: async (state, token) => {
            sessionStorage.setItem("token", token);
        },
        SET_USERAUTHORITY: async (state, userAuthority) => {
            sessionStorage.setItem("userAuthority", userAuthority);
            state.userAuthority = (await decryptData(userAuthority)).split(",")
        },
        SET_MENULIST: async (state, menuList) => {
            sessionStorage.setItem("menuList", menuList);
            state.menuList = JSON.parse((await decryptData(menuList)))
        },
        SET_USERINFO: async (state, userInfo) => {
            sessionStorage.setItem("userInfo", userInfo);
            state.userInfo = JSON.parse((await decryptData(userInfo)));
        },
        SET_SYSTEMAUTHORITY: async (state, value) => {
            sessionStorage.setItem("systemAuthority", value);
            state.systemAuthority = JSON.parse(await decryptData(value))
        },
        // SET_SLEEPAUTHORITY: async (state, value) => {
        //     sessionStorage.setItem("sleepAuthority", value);
        //     state.sleepAuthority = value
        // },
        SET_THEME: (state, theme) => {
            sessionStorage.setItem("theme", theme);
            state.theme = theme;
        },
        // 动态header tab
        ADD_TABS: (state, tab) => {
            // 遍历每一个的name 如果路径在就不添加 不在则添加
            if (state.editableTabs.findIndex(e => e.name === tab.path) === -1) {
                if (tab.path != '/vip' && tab.path != '/sleep') {
                    // push方法 添加
                    state.editableTabs.push({
                        title: tab.name,
                        name: tab.path,
                    })
                }
            }
            // 切换当前选择的顶部tab
            state.editableTabsValue = tab.path;
        },
        // 重置tab 初始化当前index 和tab的集合
        RESET_TABS: (state, tab) => {
            editableTabsValue:'/index',
                state.editableTabs = [{
                    title: '首页',
                    name: '/index',
                }]
        },
    },
    actions: {
        //登出系统
        logout() {
            window.sessionStorage.clear();
            window.localStorage.clear();
            router.replace("/login")
            /**
             * 刷新 会重新进入当前页面 重置动态路由 放在此位置 防止以下情况
             * 1 review 用户A在登录后退出再次登录用户B，用户A的路由仍存在，需刷新解决
             * 2 用户/sys请求后立刻退出还未走到重定向/login时，路径为/sys 走路由首位，（无token）弹出登陆异常信息 ，故先让/login重定向
             */
            location.reload();
        },
        // 设置锁屏状态
        async setSleep({commit, state}, value) {
            // 修改系统权限字符-锁屏判定
            let systemAuthorityString = JSON.parse(await decryptData(sessionStorage.getItem("systemAuthority")));
            if (systemAuthorityString != null)
                systemAuthorityString.sleepAuthority = value;
            const encryptedSystemAuthority = await encryptData(JSON.stringify(systemAuthorityString));
            await Promise.resolve()
                .then(() => {
                    commit('SET_SYSTEMAUTHORITY', encryptedSystemAuthority);
                })
                .then(() => {
                    // 临时将token存储在store里  解锁在归还  不需要加密解密
                    state.token = sessionStorage.getItem("token")
                })
                .then(() => {
                    // token临时清空
                    window.sessionStorage.removeItem('token');
                })
                .then(() => {
                    // 最后执行路由跳转
                    router.push('/sleep')
                });
        },
        async outSleep({commit, state}, value) {
            // 修改系统权限字符-锁屏判定
            let systemAuthorityString = JSON.parse(await decryptData(sessionStorage.getItem("systemAuthority")));
            if (systemAuthorityString != null)
                systemAuthorityString.sleepAuthority = value;
            const encryptedSystemAuthority = await encryptData(JSON.stringify(systemAuthorityString));
            await Promise.resolve()
                .then(() => {
                    commit('SET_SYSTEMAUTHORITY', encryptedSystemAuthority);
                })
                .then(() => {
                    // 归还token
                    commit('SET_SESSION_TOKEN', state.token);
                })
                .then(() => {
                    state.editableTabsValue = '/index';
                })
                .then(() => {
                    // 最后执行路由跳转
                    router.replace("/index");
                });
        }
    },
    modules: {}
})
