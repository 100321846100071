<template>
  <div id="SearchBar" :class="SearchBarClass">
    <div class="rowList">
      <!-- 遍历-->
      <div class="row" v-for="item in searchBarList" :key="item.id">
        <!--1 基础属性 输入框-->
        <div v-if="item.atrType == 'text'" class="row_item">
          <span class="row_label">{{ item.label }}</span>
          <el-input class="row_input" placeholder=""
                    v-model="queryForm[`${item.prop}`]" clearable
          ></el-input>
        </div>
        <!--2 下拉框 select-->
        <div v-if="item.atrType == 'select'" class="row_item">
          <span class="row_label">{{ item.label }}</span>
          <el-select class="row_input" v-model="queryForm[`${item.prop}`]" clearable>
            <el-option
                v-for="item in item.dictTypeValue"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!--3 时间框搜索栏开始-->
        <div class="row_item" v-if="item.atrType == 'time'">
          <span class="row_label">{{ item.label }}</span>
          <el-date-picker
              class="row_input_time"
              v-model="queryForm[`${item.prop}Begin`]"
              type="date"
              clearable
              @change="(date) => formatSelectedDate(date, item , 'Begin')"
              placeholder="开始时间">
          </el-date-picker>
        </div>
        <!--4 时间框搜索栏结束-->
        <div class="row_item" v-if="item.atrType == 'time'">
          <span class="row_label">至</span>
          <el-date-picker
              class="row_input_time"
              v-model="queryForm[`${item.prop}End`]"
              type="date"
              clearable
              @change="(date) => formatSelectedDate(date, item ,'End')"
              placeholder="结束时间">
          </el-date-picker>
        </div>
      </div>
      <el-button type="success" class="button  custom-button" :icon="Search" @click="confirm"
                 :disabled="hasAuthority(portQuery)"
                 native-type="button" style="width: 60px ; border-radius: 20px;
                 margin: 0px 10px;" color="rgba(3,233,244,.4)">搜索
      </el-button>
      <el-button type="warning" class="button" :icon="RefreshRight" @click="reset"
                 :disabled="hasAuthority(portQuery)"
                 native-type="button" style="width: 60px ; border-radius: 20px;
                 margin: 0px 10px;">重置
      </el-button>
    </div>
  </div>
</template>

<script setup>
import {Search, Delete, DocumentAdd, Edit, Tools, RefreshRight} from '@element-plus/icons-vue'
import requestUtil, {getServerUrl} from "@/util/request";
import {computed, defineEmits, defineProps, ref, watch, onMounted, nextTick} from "vue";
import {ElMessage, ElMessageBox} from 'element-plus'
import store from '@/store'

const currentUser = store.state.userInfo
const emits = defineEmits(['getSearchBarHeight', 'test'])
const tableData = ref([]);
const SearchBarClass = ref();
const searchBarList = ref([])
const queryForm = ref({
  pageNum: 1,
  pageSize: 10,
  userId: null,
})
watch(
    //  props.height 初始化从0到实际高度  单刷新发生监听 （一次）
    //  props.allList 每次添加 或者删除 只要数据发生改变就会监听 （n次） store.state.theme
    () => [props.checkList, props.searchBarVisible, props.screenWidth, props.allList, props.rowItemWidth, store.state.theme],
    ([newCheckList, newSearchBarVisible, newScreenWidth, newAllList, newRowItemWidth, newTheme], [oldCheckList, oldSearchBarVisible, oldScreenWidth, oldAllList, oldRowItemWidth, oldTheme]) => {
      // 根据class 切换同时控制主题  主题变化了
      // console.log(props.searchBarVisible)
      if (props.searchBarVisible == true) {
        // 只有在主题切换和 刷新时候 才 store.state.theme 每次刷新全局最新渲染 layout/index 渲染的
        // 改变主题 或 两次刷新
        if (newTheme != oldTheme || (newTheme == 'white' && oldTheme == 'white') || (newTheme == 'black' && oldTheme == 'black')) {
          if (store.state.theme && store.state.theme == 'white') {
            SearchBarClass.value = "openSearchBarWhite";
          } else {
            SearchBarClass.value = "openSearchBarBlack";
          }
        }
      } else if (props.searchBarVisible == false) {
        if (newTheme != oldTheme || (newTheme == 'white' && oldTheme == 'white') || (newTheme == 'black' && oldTheme == 'black')) {
          if (store.state.theme && store.state.theme == 'white') {
            SearchBarClass.value = "closeSearchBarWhite";
          } else {
            SearchBarClass.value = "closeSearchBarBlack";
          }
        }
      }
      // 更新新的列表
      searchBarList.value = []
      // console.log(props.checkList)
      // 过滤不搜索的属性
      props.checkList.forEach((item) => {
        props.allList.forEach((i) => {
          // 有页面 且 搜索方式非 不搜索
          if (item == i.prop && i.atrDisplay != null && i.atrDisplay.includes('query') && i.queryType != 'none')
            searchBarList.value.push(i);
        });
      });
      if (props.searchBarVisible == true) {
        // 根据当前宽度设置每一个item的input的宽度
        setRowItemWidth(props.rowItemWidth);
        // 加载了下面函数里面
        nextTick(() => {
          resetSearchHeight();
        })
      }
    }
)
// 都渲染完在更新
onMounted(() => {

})

// 每次的真正的高度赋值  已经判断完是打开状态
const resetSearchHeight = () => {
  // 两者都有用
  // 再次判断防止重复点击
  if (props.searchBarVisible == true) {
    // console.log("高度动画开启")
    var SearchBar = document.querySelector('.rowList');
    // console.log(store.state.theme)
    // console.log(props.searchBarVisible)
    if (store.state.theme && store.state.theme == 'white') {
      var openSearchBar = document.querySelector('.openSearchBarWhite');
    } else {
      var openSearchBar = document.querySelector('.openSearchBarBlack');
    }
    // console.log(openSearchBar)
    openSearchBar.style.height = SearchBar.offsetHeight + 'px';
  }
}
// 时间搜索框跟随搜索栏宽度实时改变
const setRowItemWidth = (rowItemWidth) => {
  // console.log("宽度设置"+props.rowItemWidth)
  var row_labels = document.querySelectorAll('.row_item .row_label');
  var row_inputs = document.querySelectorAll('.row_item .row_input');
  var row_input_times = document.querySelectorAll('.row_item .row_input_time .el-input__wrapper');
  if (rowItemWidth < 300) {
    // 设置相对宽度
    row_labels.forEach(item => {
      item.style.width = rowItemWidth * 0.3 + 'px';
    })
    row_inputs.forEach(item => {
      item.style.width = rowItemWidth * 0.5 + 'px';
    })
    row_input_times.forEach(item => {
      item.style.maxWidth = rowItemWidth * 0.25 + 'px';
    })
  } else if (rowItemWidth >= 300) {
    // 设置绝对宽度
    row_inputs.forEach(item => {
      item.style.width = 200 + 'px';
    })
    row_input_times.forEach(item => {
      item.style.maxWidth = 90 + 'px';
    })
  }
}
// 时间重置格式
const formatSelectedDate = (date, item, value) => {
  if (date) {
    var date = new Date(date);
    var name = item.prop + value;
    var formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    queryForm.value[`${name}`] = formattedDate;
  }
}
// 提交(点击搜索)初始化表单 传入子组件选择的列表
const confirm = async () => {
  Object.keys(queryForm.value).forEach(key => {
    if (queryForm.value[key] == "") {
      delete queryForm.value[key];
    }
  });
  queryForm.value.userId = currentUser.id
  const res = await requestUtil.post(props.port + '/list', queryForm.value);
  // console.log("↓搜素返回结果↓")
  // console.log(res)
  if (res.data.code == 647) {
    emits('confirm', res, queryForm.value)
  } else {
    console.log(res)
    if (res.data.msg) {
      ElMessage.error(res.data.msg);
    } else {
      ElMessage.error("没有搜索结果");
    }
    emits('confirm', null)
  }
  // 父组件的方法cinfirm 传入的参数 需两端同时定义一样的方法
}

// 重置
const reset = async () => {
  queryForm.value = {
    pageNum: 1,
    pageSize: 10,
    userId: currentUser.id,
  }
  confirm();
}
// 设置权限 匹配查询query字符按
const portQuery = computed(() => {
  if (props.port != null) {
    let newAuthrity = props.port + '/query';
    return newAuthrity.replace(/^\//, '').replace(/\//g, ':');
  } else return ''
})
/**
 * 父组件传入数据
 * 接口参数
 * 列表集合
 */
const props = defineProps({
  searchBarVisible: {
    type: Boolean,
    default: '',
    required: true,
  },
  port: {
    type: String,
    default: "",
  },
  screenWidth: {
    type: Number,
    default: '',
    required: true,
  },
  allList: {
    type: Array,
    default: [],
    required: true,
  },
  checkList: {
    type: Array,
    default: [],
    required: true,
  },
  rowItemWidth: {
    type: Number,
    default: 300,
    required: true,
  }
})

</script>

<style lang="scss" scoped>


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 白主题
.openSearchBarWhite {
  height: auto;
  border: 1px solid #ccc;
  box-shadow: 1px 1px #ccc;
  border-radius: 10px;
  padding: 5px;
  transition: height 0.5s ease;

  .rowList {
    //display: flex;
    //flex-wrap: wrap; /* 允许内容换行 */
    .row {
      display: inline-block;
      margin: 0px 1px !important;

      .row_item {
        //border: 1px solid red;
        display: inline-block;
        width: auto;
        white-space: nowrap;
        margin: 0px 0px !important;

        .row_label {
          color: var(--text-color-theme-white);
          width: auto;
          display: inline-block;
          text-align: center;
          margin: 5px 5px;
        }

        // 输入框
        .row_input {
          margin: 5px 0px !important;
          width: 200px;
          color: black;
          // 白主题
          :deep .el-input__wrapper {
            background-color: white;
            // 字体颜色
            .el-input__inner {
              color: var(--text-color-theme-white);
            }
          }
        }

        :deep .row_input_time {
          font-size: 10px;
          width: 90px !important;
          // 白主题
          .el-input__wrapper {
            background-color: white;
            // 字体颜色
            .el-input__inner {
              color: var(--text-color-theme-white);
            }
          }
        }
      }
    }

    .button {
      //border: 1px solid white;
      //background-color: transparent;
      //color: white
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 黑主题
.openSearchBarBlack {
  height: auto;
  border: 1px solid #ccc;
  box-shadow: 1px 1px #ccc;
  border-radius: 10px;
  padding: 5px;
  transition: height 0.5s ease;

  .rowList {
    display: flex;
    flex-wrap: wrap; /* 允许内容换行 */
    .row {
      display: inline;
      margin: 0px 1px !important;

      .row_item {
        //border: 1px solid red;
        display: inline;
        width: auto;
        white-space: nowrap;
        margin: 0px 0px !important;

        .row_label {
          color: var(--text-color-theme-black);
          width: auto;
          display: inline-block;
          text-align: center;
          margin: 5px 5px;
        }

        // 输入框
        .row_input {
          margin: 5px 0px !important;
          width: 200px;
          // 黑主题
          :deep .el-input__wrapper {
            background-color: var(--background-color-theme-black);
            // 字体颜色
            .el-input__inner {
              color: white;
            }
          }
        }

        :deep .row_input_time {
          font-size: 10px;
          width: 90px !important;
          // 黑主题
          .el-input__wrapper {
            background-color: var(--background-color-theme-black);
            // 字体颜色
            .el-input__inner {
              color: white;
            }
          }
        }
      }
    }

    .button {
      border: 1px solid white;
      background-color: transparent;
      color: white
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.closeSearchBarWhite {
  //border: 1px solid green !important;
  width: 0px;
  height: 0px !important;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  border: 0px solid #ccc;
  box-shadow: 0px 0px #ccc;
  border-radius: 0px;
  padding: 0px;
  transition: height 0.5s ease;

  .rowList {
    display: flex;
    flex-wrap: wrap; /* 允许内容换行 */
    .row {
      display: inline;
      margin: 0px 0px !important;

      .row_item {
        display: inline;
        width: 0px;
        white-space: nowrap;
        margin: 0px 0px !important;

        .row_label {
          color: var(--text-color-theme-white);
          display: inline-block;
          text-align: center;
          margin: 0px 0px;
        }

        .row_input {
          //border: 1px solid red;
          margin: 0px 0px !important;
          width: 0px;
        }
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.closeSearchBarBlack {
  //border: 1px solid green !important;
  width: 0px;
  height: 0px !important;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  border: 0px solid #ccc;
  box-shadow: 0px 0px #ccc;
  border-radius: 0px;
  padding: 0px;
  transition: height 0.5s ease;

  .rowList {
    display: flex;
    flex-wrap: wrap; /* 允许内容换行 */
    .row {
      display: inline;
      margin: 0px 0px !important;

      .row_item {
        display: inline;
        width: 0px;
        white-space: nowrap;
        margin: 0px 0px !important;

        .row_label {
          color: var(--text-color-theme-black);
          display: inline-block;
          text-align: center;
          margin: 0px 0px;
        }

        .row_input {
          //border: 1px solid red;
          margin: 0px 0px !important;
          width: 0px;
        }
      }
    }

    .button {
      border: 1px solid white;
      background-color: transparent;
      color: white
    }
  }
}

.custom-button {
  //width: 60px;
  //border-radius: 20px;
  //margin: 0 10px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //background-color: rgba(3, 233, 244, 0.4);
}

.custom-button .el-icon {
  pointer-events: none; /* 确保图标不会阻挡按钮的点击 */
  margin-right: 5px; /* 图标与文本之间增加间距 */
}

.custom-button:hover {
  cursor: pointer;
}
</style>
