import store from "@/store";
import {decryptData} from "@/util/decryptData";

export const buttonAuthority = {
    methods: {
        hasAuthority(perm) {
            return (store.state.userAuthority).indexOf(perm) < 0
        },
        showAuthority(perm) {
            return (store.state.userAuthority).indexOf(perm) >= 0
        }
    }
}
export default buttonAuthority;

