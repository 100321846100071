<template>
  <el-dialog :title="dialogTitle()" :width="elDialogWidth" center @close="handleClose" style="height: 75%"
             custom-class="dialog"
  >
    <el-form ref="formRef" :model="queryForm" label-width="200px" class="form"
             v-loading="loading" element-loading-text="数据加载中"
             element-loading-background="rgb(255,255,255)">
      <div v-for="(item, index) in allList" :key="index" class="formDiv">
        <!-- 文本 text BasicAtrType ===========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-if="'text' === formatVIFCheckBasicAtrType(item)"
                      :rules="rules[`${item.prop}`]">
          <el-input v-model="queryForm[`${item.prop}`]" class="row_input" :placeholder="item.placeholder"
                    :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)" clearable>
          </el-input>
        </el-form-item>
        <!-- 备注 textarea BasicAtrType ===========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="'textarea'=== formatVIFCheckBasicAtrType(item)"
                      :rules="rules[`${item.prop}`]">
          <el-input v-model="queryForm[`${item.prop}`]" class="row_input" :placeholder="item.placeholder"
                    :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)" type="textarea" :rows="3" clearable>
          </el-input>
        </el-form-item>
        <!-- 排序 sort BasicAtrType ===========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="'sort'=== formatVIFCheckBasicAtrType(item)"
                      :rules="rules[`${item.prop}`]">
          <el-input-number v-model="queryForm[`${item.prop}`]" :placeholder="item.placeholder"
                           :min="1" class="row_input"
                           :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)" clearable>
          </el-input-number>
        </el-form-item>
        <!-- 数字 number BasicAtrType ===========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="'number'=== formatVIFCheckBasicAtrType(item)"
                      :rules="rules[`${item.prop}`]">
          <el-input-number v-model="queryForm[`${item.prop}`]" :placeholder="item.placeholder"
                           :min="1" class="row_input"
                           :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)" clearable>
          </el-input-number>
        </el-form-item>
        <!-- time 时间  ========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="'time' === formatVIFCheckBasicAtrType(item)"
                      :rules="rules[`${item.prop}`]">
          <el-input v-model="queryForm[`${item.prop}`]" class="row_input" :placeholder="item.placeholder"
                    :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)"></el-input>
          <!--          <el-date-picker-->
          <!--              class="row_input_time" v-model="queryForm[`${item.prop}`]" :placeholder="item.placeholder"-->
          <!--              type="date" clearable :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)">-->
          <!--          </el-date-picker>-->
        </el-form-item>
        <!-- select1 数据下拉 正常禁用专属样式 优先显示 ========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop"
                      v-else-if="'select' === formatVIFCheckBasicAtrType(item) && item.dictType === 'system_basic_status'"
                      :rules="rules[`${item.prop}`]">
          <el-radio-group v-model="queryForm[`${item.prop}`]"
                          :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)">
            <!-- label 绑定的是传回的值-->
            <el-radio v-for="option in item.dictTypeValue" :key="option.id" :label="option.value"
                      style="margin: 0px 50px 0 0px">
              {{ option.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- select2 数据下拉框  数据字典专属 优先显示 ================================================================= -->
        <el-form-item :label="item.label" :prop="item.prop"
                      v-else-if="'select' === formatVIFCheckBasicAtrType(item) && item.prop == 'dictType'"
                      :rules="rules[`${item.prop}`]">
          <!-- 此处不是函数 是固定值  SelectDictTypeValue-->
          <el-select v-model="queryForm[`${item.prop}`]" clearable class="row_input"
                     :disabled="SelectDictTypeValue" :placeholder="item.placeholder">
            <el-option v-for="option in item.dictTypeValue" :key="option.id" :label="option.label"
                       :value="option.value"/>
          </el-select>
        </el-form-item>
        <!-- select3 数据下拉框  显示页面(数组) 优先显示 ===========================================================-->
        <el-form-item :label="item.label" :prop="item.prop"
                      v-else-if="'select' === formatVIFCheckBasicAtrType(item) && item.prop == 'atrDisplay'"
                      :rules="rules[`${item.prop}`]">
          <el-select v-model="queryForm[`${item.prop}`]" :placeholder="item.placeholder" multiple clearable
                     collapse-tags class="row_input"
                     @change="(value) => formatArrayToStringAndCheckContradiction(value, item)"
                     :disabled="formatCheckReadOnlyAndSelectChangeDictType(item)">
            <el-option v-for="option in item.dictTypeValue" :key="option.id" :label="option.label"
                       :value="option.value"/>
          </el-select>
        </el-form-item>
        <!-- select4 数据下拉框  基本类型 ===========================================================-->
        <el-form-item :label="item.label" :prop="item.prop" :rules="rules[`${item.prop}`]"
                      v-else-if="'select' === formatVIFCheckBasicAtrType(item)&& item.prop !='dictType' && item.prop !='atrDisplay' ">
          <el-select v-model="queryForm[`${item.prop}`]" clearable class="row_input"
                     :disabled=formatCheckReadOnlyAndSelectChangeDictType(item) :placeholder="item.placeholder">
            <el-option v-for="option in item.dictTypeValue" :key="option.id" :label="option.label"
                       :value="option.value"/>
          </el-select>
        </el-form-item>
        <!-- editor 富文本编辑器  BasicAtrType-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="'editor' === formatVIFCheckBasicAtrType(item)"
                      :rules="rules[`${item.prop}`]">
          <QuillEditor
              :ref="setEditorRef(index)"
              :key="quillEditorKey"
              v-model:content="queryForm[`${item.prop}`]"
              contentType="html"
              theme="snow"
              :options="editorSelectChange(index)"
              class="editorUploader"
          >
          </QuillEditor>
          <input
              :ref="setEditorInputRef(index)"
              type="file"
              accept=".png,.jpg,.jpeg"
              @change="editorInputChange($event, index)"
              id="editor"
              style="display: none;border: 4px solid red"
          />
        </el-form-item>
        <!-- image 图片   ===========================================================================================-->
        <!-- image 图片   ===========================================================================================-->
        <!-- image 图片   ===========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="formatVIFCheckImage(item)"
                      :rules="rules[`${item.prop}`]" class="imageItem" :key="1">
          <el-upload
              :ref="setUploadImageRef(index)" :key="imageRefKey" class="imageUploader"
              :headers="headers" disabled :drag="true"
              :file-list="fileListArrayByImage" :show-file-list="true" :auto-upload="false"
              :on-change="handleImageFileChange"
              :action="getServerUrl()+ item.port + '/uploadImage'"
              :on-success="(response, file, fileList) => handleImageSuccess(item, response, file, fileList)"
              :disabled="formatCheckReadOnlyAndSelectChangeDictType(item) || elDialogWidth<'80%'?false:true"
          >
            <div class="imageOutDiv">
              <el-image :ref="setImageRef(index)" class="avatarImg" :src="imageUrl[index]"
                        :preview-src-list="previewImage[index]" disabled fit="contain" loading="lazy" lazy>
                <template #placeholder>
                  <img src="@/assets/img/imageLoading.png" class="lazyImg" alt="placeholder">
                </template>
                <template #error>
                  <img src="@/assets/img/imageLoadingError.jpg" class="lazyImg" alt="加载失败">
                </template>
              </el-image>
            </div>
          </el-upload>
          <div class="menu">
            <el-switch v-model="isCropper" active-color="green" inactive-color="red" active-text="裁剪" inactive-text="原图"
                       active-value="true" inactive-value="false" class="el-switch"
                       @click="selectImageType(index)" v-if="!formatCheckReadOnlyAndSelectChangeDictType(item)">
            </el-switch>
            <el-button class="menuButton" @click="selectImage(index)" type="success"
                       v-if="!formatCheckReadOnlyAndSelectChangeDictType(item)"
            >选择图片
            </el-button>
            <el-button class="menuButton" v-if="!formatCheckReadOnlyAndSelectChangeDictType(item)"
                       @click="submitUploadImage(item,index)"
                       type="danger"
            >上传图片
            </el-button>
            <el-button class="menuButton" @click="viewAvatar(index)" v-if="queryForm[`${item.prop}`]"
            >预览大图
            </el-button>
            <el-button class="menuButton" @click="imageToView(index)" v-if="queryForm[`${item.prop}`]">
              图片信息
            </el-button>
            <el-button class="menuButton" @click="downloadImage(index)">下载</el-button>
          </div>
        </el-form-item>
        <!-- vadio 音频 ===========================================================================================-->
        <!-- vadio 音频 ===========================================================================================-->
        <!-- vadio 音频 ===========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="formatVIFCheckAudio(item)"
                      :rules="rules[`${item.prop}`]" class="audioItem">
          <el-upload
              :ref="setUploadAudioRef(index)" :key="audioRefKey" class="audioUploader"
              :headers="headers" :drag="true" :file-list="fileListArrayByAudio" :auto-upload="false"
              :on-change="handleAudioFileChange"
              :action="getServerUrl()+ item.port + '/uploadAudio'"
              :on-success="(response, file, fileList) =>handleAudioSuccess(item, response, file, fileList)"
              :disabled="formatCheckReadOnlyAndSelectChangeDictType(item) || elDialogWidth<'80%'?false:true"
          >
            <div class="title" v-if="!audioButton[index]">
              <div slot="tip" class="title_tip">支持拖拽音频上传</div>
              <div slot="tip" class="title_tip">只支持 [mpeg、flac] 格式的音频文件</div>
              <div slot="tip" class="title_tip">文件大小不超过{{ videoSizeType }}M</div>
            </div>
            <audio v-if="audioVisible[index]" :ref="setAudioRef(index)" controls class="audio" :muted="mutedAudio">
              <source :src="audioUrl[index]" type="audio/flac">
              <source :src="audioUrl[index]" type="audio/mpeg">
            </audio>
          </el-upload>
          <div class="right">
            <el-button class="audioControllerButton" @click="selectAudio(index)"
                       v-if="!formatCheckReadOnlyAndSelectChangeDictType(item)"
            >选择音频
            </el-button>
            <el-button class="menuButton" @click="submitUploadAudio(item, index)"
                       v-if="!formatCheckReadOnlyAndSelectChangeDictType(item)"
            >上传音频
            </el-button>
            <div v-if="audioButton" class="audioButton">
              <el-button class="audioControllerButton" @click="playAudio(index)">播放▶️</el-button>
              <el-button class="audioControllerButton" @click="pauseAudio(index)">暂停⏸️</el-button>
              <el-button class="audioControllerButton" @click="audioAudio(index)">声音开/关🔊️</el-button>
              <el-button class="audioControllerButton" @click="downloadAudio(index)">下载</el-button>
            </div>
          </div>
        </el-form-item>
        <!-- video 视频 ===========================================================================================-->
        <!-- video 视频 ===========================================================================================-->
        <!-- video 视频 ===========================================================================================-->
        <el-form-item :label="item.label" :prop="item.prop" v-else-if="formatVIFCheckVideo(item)"
                      :rules="rules[`${item.prop}`]" class="videoItem">
          <el-upload
              :ref="setUploadVideoRef(index)" :key="videoRefKey" class="videoUploader"
              :headers="headers" :drag="true" :file-list="fileListArrayByVideo" :auto-upload="false"
              :on-success="(response, file, fileList) =>handleVideoSuccess(item, response, file, fileList)"
              :on-change="handleVideoFileChange"
              :action="getServerUrl()+ item.port + '/uploadVideo'"
              :disabled="formatCheckReadOnlyAndSelectChangeDictType(item) || elDialogWidth<'80%'?false:true"
          >
            <div class="title" v-if="!videoButton[index]">
              <div slot="tip" class="title_tip">支持拖拽视频上传</div>
              <div slot="tip" class="title_tip">只支持 [mp4、avi、mov、Hevc] 格式的视频文件</div>
              <div slot="tip" class="title_tip">文件大小不超过{{ videoSizeType }}M</div>
            </div>
            <video v-if="videoVisible[index]" :ref="setVideoRef(index)" controls class="video" :muted="mutedVideo"
                   :poster="placeholderImageUrl">
              <source :src="videoUrl[index]" type="video/mp4">
            </video>
          </el-upload>
          <div class="right">
            <el-button class="videoControllerButton" @click="selectVideo(index)"
                       v-if="!formatCheckReadOnlyAndSelectChangeDictType(item)"
            >选择视频
            </el-button>
            <el-button class="videoControllerButton" @click="submitUploadVideo(item,index)"
                       v-if="!formatCheckReadOnlyAndSelectChangeDictType(item)"
            >上传视频
            </el-button>
            <div v-if="videoButton[index]" class="videoButton">
              <el-button class="videoControllerButton" @click="playVideo(index)">播放▶️</el-button>
              <el-button class="videoControllerButton" @click="pauseVideo(index)">暂停⏸️</el-button>
              <el-button class="videoControllerButton" @click="audioVideo(index)">声音开/关🔊</el-button>
              <el-button class="videoControllerButton" @click="downloadVideo(index)">下载</el-button>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button v-if="confimType" type="primary" @click="handleConfirm">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </div>
    </template>
    <CropperDialog
        v-model="cropperDialogVisible"
        :cropperDialogWidth="cropperDialogWidth"
        :cropperDialogVisible="cropperDialogVisible"
        :cropperDialogTitle="cropperDialogTitle"
        :cropperImageSrc="cropperImageSrc"
        @handleReplaceImg="handleReplaceImg"
    >
    </CropperDialog>
    <CropperEditorDialog
        v-model="cropperEditorDialogVisible"
        :cropperDialogWidth="cropperEditorDialogWidth"
        :cropperDialogVisible="cropperEditorDialogVisible"
        :cropperDialogTitle="cropperEditorDialogTitle"
        :cropperImageSrc="cropperEditorImageSrc"
        :cropperImageIndex="cropperEditorDialogIndex"
        @handleReplaceEditorImg="handleReplaceEditorImg"
    >
    </CropperEditorDialog>
    <ImageView
        v-model="imageViewDialogVisible"
        :dialogVisible="imageViewDialogVisible"
        :dialogTitle="imageViewDialogTitle"
        :elDialogWidth="imageViewWidth"
        :imageKey="imageKey"
        :imageIndex="imageIndex"
        :id="props.id"
    >
    </ImageView>
  </el-dialog>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  ref,
  watch,
  onMounted,
  reactive,
  nextTick,
  watchEffect,
  computed,
  onUnmounted,
  onBeforeUnmount
} from "vue"
import requestUtil, {
  getImageUrl,
  getServerUrl,
  getVideoUrl,
  getAudioUrl
} from "@/util/request";
import {ElMessage} from 'element-plus'
import store from "@/store";
import ImageView from "@/components/imageView";
import basicImage from '@/assets/img/default.png'
import imageLoading from '@/assets/img/imageLoading.png'
import videoImage from '@/assets/img/videoImage.jpg'

const isUploadSuccess = ref(false)  // 全局视频 音频 上传结果回调
const loading = ref(true)
const currentUser = store.state.userInfo

///////////////////////↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓//////////////////////////////////////////////
///////////////////////Editor Editor Editor Editor Editor Editor Editor Editor Editor //////////////////////////////////////////
///////////////////////Editor Editor Editor Editor Editor Editor Editor Editor Editor //////////////////////////////////////////
const cropperEditorDialogWidth = ref();
const cropperEditorDialogVisible = ref(false);
const cropperEditorDialogTitle = ref();
const cropperEditorDialogIndex = ref();
const cropperEditorImageSrc = ref();
const uploadEditorRef = ref({});
const uploadEditorInputRef = ref({});
const quillEditorKey = ref(1);
// 关闭富文本
const clearEditor = () => {
  quillEditorKey.value++;
  props.allList.forEach((item, index) => {
    if (item.atrType == 'editor') {
      queryForm.value[`${item.prop}`] = ''
    }
  });
}
// 动态设置上传富文本ref
const setEditorRef = (index) => {
  return editor => {
    uploadEditorRef.value[index] = editor;
  };
};
// 动态设置上传富文本ref
const setEditorInputRef = (index) => {
  return editorInput => {
    uploadEditorInputRef.value[index] = editorInput;
  };
};
// 富文本编辑器 参数设置
const editorSelectChange = (index) => {
  return {
    //富文本配置项
    placeholder: "请输入商品参数,图片和文字!",
    theme: "snow",
    modules: {
      ImageExtend: {  // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
        name: 'img',  // 图片参数名
        size: 3,  // 可选参数 图片大小，单位为M，1M = 1024kb
        action: '',  // 服务器地址, 如果action为空，则采用base64插入图片
        headers: async (xhr) => {
          xhr.setRequestHeader('token', await decryptData(sessionStorage.getItem("token")))
        },
      },
      // 上传到服务器
      toolbar: {
        container: container,  // container为工具栏，此次引入了全部工具栏，也可自行配置
        handlers: {
          'image': function (value) {  // 劫持原来的图片点击按钮事件
            if (value) {
              uploadEditorInputRef.value[index].click();
            } else {
              this.quill.format("image", false);
            }
          }
        }
      }
    }
  }
}
// 富文本按钮绑定事件
const editorInputChange = async (e, index) => {
  let file = e.target.files[0];
  if (beforeImageEditorUpload(file)) {
    const reader = new FileReader();
    reader.onload = function (e) {
      var base64String = e.target.result;
      cropperEditorImageSrc.value = base64String;
    };
    reader.readAsDataURL(file)
    cropperEditorDialogTitle.value = "富文本图片裁剪"
    cropperEditorDialogIndex.value = index
    setDialogWidth();
    cropperEditorDialogVisible.value = true;
  } else {
    return;
  }
}
// 图片判断
const beforeImageEditorUpload = (file) => {
  const isJPG = file.type === 'image/jpeg'
  const isPNG = file.type === 'image/png';
  const isLt2M = file.size / 1024 / 1024 < 10
  if (!isJPG && !isPNG) {
    ElMessage.error('图片必须是jpg或png格式')
  }
  if (!isLt2M) {
    ElMessage.error('图片过大!')
  }
  return (isJPG || isPNG) && isLt2M
}
// (富文本裁剪提交)  接受裁剪后的头像
const handleReplaceEditorImg = async (imgUrl, file, index) => {
  cropperEditorDialogVisible.value = false;
  let formData = new FormData();
  formData.append('file', file, "basic.jpg")
  ElMessage.info("文件正在上传")
  let result = await requestUitl.post(props.port + '/uploadImage', formData);
  // let result = await requestUitl.post("/product/detail/uploadImage", formData);
  if (result.data.code == 647) {
    ElMessage.success("文件上传成功")
    let quill = uploadEditorRef.value[index].getQuill() // 获取这个富文本的光标
    let length = quill.getSelection().index; //光标位置
    quill.insertEmbed(length, "image", {src: getServerUrl() + result.data.src, width: '100%'});  // 插入图片
    quill.setSelection(length + 1); // 调整光标内容后面
    let lengthNow = quill.getSelection().index; //光标位置
    quill.insertText(lengthNow, '\n'); // 换行
  }
}
///////////////////////↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ //////////////////////////////////////////////
///////////////////////Editor Editor Editor Editor Editor Editor Editor Editor Editor //////////////////////////////////////////
///////////////////////Editor Editor Editor Editor Editor Editor Editor Editor Editor //////////////////////////////////////////


///////////////////////↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓//////////////////////////////////////////////
///////////////////////Image Image Image Image Image Image Image Image Image //////////////////////////////////////////
///////////////////////Image Image Image Image Image Image Image Image Image ////////////////////////////////////////////////////////////////////////
import {container} from "quill-image-extend-module";
import requestUitl from "@/util/request";
import CropperEditorDialog from "@/components/cropperEditorDialog";
import {decryptData} from "@/util/decryptData";

const uploadImageRef = ref({});
const fileListArrayByImage = ref([]);
const imageRef = ref({});
// 裁剪图片dialog
const cropperDialogWidth = ref('');
const cropperDialogVisible = ref(false);
const cropperDialogTitle = ref("无标题");
const cropperImageSrc = ref('');
const cropper = ref();
const headers = ref({
  token: ''
})
const isCropper = ref("true")
const fileListNumbyImage = ref(0)
const ImageSizeType = ref(currentUser && currentUser.username == 'C' && currentUser.roles.includes('超级管理员') ? 30 : 2);
const imageRefKey = ref(0)
const isUploaded = ref(false)
const imageUrl = ref([]);// 图片
const imageIndex = ref();// 传递的图片信息index
// 动态设置上传图片ref
const setUploadImageRef = (index) => {
  return el => {
    uploadImageRef.value[index] = el;
  };
};
// 动态设置图片组件ref
const setImageRef = (index) => {
  return im => {
    imageRef.value[index] = im;
  };
};

// 表单检测 （图片）
const formatVIFCheckImage = (item) => {
  if (item.atrType == 'image') {
    return true;
  } else return false;
}
const formatVIFCheckVideo = (item) => {
  if (item.atrType == 'video') {
    return true;
  } else return false;
}
const formatVIFCheckAudio = (item) => {
  if (item.atrType == 'audio') {
    return true;
  } else return false;
}

// 关闭图片相关信息
const clearImageMsg = () => {
  checkHasImage()
  if (hasImageType.value) {
    fileListArrayByImage.value = []
    isUploadSuccess.value = false;
    fileListNumbyImage.value = 0;
    imageRefKey.value++;
    cropperImage.value = null;
    isUploaded.value = false;
  }
  // 图片框存在
  if (uploadImageRef.value) {
    imageUrl.value = []
  }
}
const prepareViewImage = ref([])  // 拟预览图
const previewImage = ref([])  // 预览图
// 点击图片信息
const imageToView = (index) => {
  imageViewDialogVisible.value = !imageViewDialogVisible.value
  imageViewDialogTitle.value = '';
  imageIndex.value = index;
  imageEcho();
  setDialogWidth()
}

// 点击查看大图  添加/编辑
const viewAvatar = (index) => {
  if (imageRef.value) {
    previewImage.value[index] = prepareViewImage.value[index];
    nextTick(() => {
      const imageElement = imageRef.value[index]?.$el?.querySelector('img');
      if (imageElement) {
        imageElement.click();
      } else {
        ElMessage.error('异常');
      }
    });
  }
};
// 图片 监控检测清空关闭后的图片列表
const checkClickClearPreviewImage = (event) => {
  let target = event.target
  while (target != null && target != document) {
    if (target.classList.contains('el-image-viewer__close')) {
      // 关闭预览窗口清空图片列表
      previewImage.value = []
      return;
    }
    // 移动到父元素
    target = target.parentNode;
  }
};

// 判断是否有图片上传标签
const hasImageType = ref(false)
const checkHasImage = () => {
  props.allList.forEach(item => {
    if (item.atrType === 'image') {
      hasImageType.value = true;
      return;
    }
  });
}
// 下载
const downloadImage = async (index) => {
  // 获取视频资源
  const response = await fetch(imageUrl.value[index]);
  const imageBlob = await response.blob();
  // 创建 Blob 对象并生成下载链接
  const blob = new Blob([imageBlob], {type: 'image/png'});
  const url = window.URL.createObjectURL(blob);
  // 创建一个下载链接
  const a = document.createElement('a');
  a.href = url;
  let name = '下载文件'
  a.download = name + '.png'; // 设置下载文件的名称
  a.target = '_blank'; // 在新窗口中打开下载链接
  // 模拟点击下载链接
  document.body.appendChild(a);
  a.click();
  // 释放资源
  window.URL.revokeObjectURL(url);
  // 移除下载链接
  document.body.removeChild(a);
}
const imageViewDialogVisible = ref(false)
const imageViewDialogTitle = ref("预览大图")
const imageViewWidth = ref('80%')
const imageKey = ref([])
const cropperImage = ref(null)

// 图片回显(选择性回显）  遍历allList 找到atrype = image 的在从queryForm找到 data
const imageEcho = () => {
  // 编辑中查看大图
  if (props.dialogVisible == true && props.AddOrEditDialogStatus != 1) {
    props.allList.forEach((item, index) => {
      if (item.atrType == 'image') {
        // 编辑状态 下的两种情况
        const defaultImageUrl = getServerUrl() + item.reflection + '/' + queryForm.value[`${item.prop}`];
        prepareViewImage.value[index] = [defaultImageUrl];
        imageUrl.value[`${index}`] = defaultImageUrl;
        imageKey.value[`${index}`] = imageUrl.value[`${index}`]
      }
    });
  }
  if (props.dialogVisible == true && props.AddOrEditDialogStatus == 1) {
    // 添加
    props.allList.forEach((item, index) => {
      if (item.atrType == 'image') {
        imageUrl.value[`${index}`] = basicImage;
        imageKey.value[`${index}`] = imageUrl.value[`${index}`];
      }
    });
  }
}
// 切换原图/裁剪
const selectImageType = (index) => {
  ElMessage.success("图片模式已修改,请重新选择图片!")
  if (uploadImageRef.value[index]) {
    uploadImageRef.value[index].clearFiles();
    // 调用 clearImageMsg 的部分
    fileListArrayByImage.value = []
    isUploadSuccess.value = false;
    fileListNumbyImage.value = 0;
    imageRefKey.value++;
    cropperImage.value = null;
    isUploaded.value = false;
  }
}
// 点击选择图片
const selectImage = (index) => {
  if (uploadImageRef.value[index]) {
    const uploadButton = uploadImageRef.value[index].$el.querySelector('.el-upload__input');
    if (uploadButton) {
      uploadButton.click();
    }
  }
};
// 首次图片文件触发
const handleImageFileChange = async (file, fileList) => {
  if (file.response && file.response.code == 647) {
  } else {
    isUploadSuccess.value = false;
    const isJPG = file.raw.type === 'image/jpeg'
    const isPNG = file.raw.type === 'image/png';
    const isHELF = file.raw.type === 'image/helf';
    if (currentUser && currentUser.username == 'C' && currentUser.roles.includes('超级管理员'))
      ImageSizeType.value = 30;
    const isSize = file.raw.size / 1024 / 1024 < ImageSizeType.value
    if (!isJPG && !isPNG && !isHELF) {
      ElMessage.error('图片必须是jpg或png或helf格式!')
      fileList.pop();
    }
    if (!isSize) {
      ElMessage.error('图片大小不能超过' + ImageSizeType.value + 'M!')
      fileList.pop();
    }
    if (fileList.length > 1) {
      fileList[0] = fileList[1]
      fileList.pop();
    }
    // 判断文件是否规范
    if ((isJPG || isPNG) && isSize) {
      // 判断图片处理模式
      if (isCropper.value === "true") {
        // 若开启裁剪 为裁剪做准备 （64编码）
        const reader = new FileReader();
        reader.onload = function (e) {
          var base64String = e.target.result;
          cropperImageSrc.value = base64String;
        };
        reader.readAsDataURL(file.raw)
        // 开启图片裁剪
        cropperDialogTitle.value = "图片裁剪"
        setDialogWidth();
        cropperDialogVisible.value = !cropperDialogVisible.value;
      } else if (isCropper.value === "false") {
        // 不开启裁剪
        fileListNumbyImage.value = fileList.length;
        ElMessage.error("如需预览请点击上传!")
      }
    } else {
      return
    }
  }
}
// (裁剪提交)接受裁剪后的头像 imgUrl 实时预览图
const handleReplaceImg = async (imgUrl, file) => {
  cropperDialogVisible.value = false;
  cropperImage.value = file
  ElMessage.error("如需预览请点击上传!")
  isUploadSuccess.value = false;
}

// 点击上传图片
const submitUploadImage = async (item, index) => {
  // 裁剪过 自行上传 图片小不会超时
  if (cropperImage.value && imageUrl.value) {
    let formData = new FormData();
    formData.append('file', cropperImage.value, "basic.jpg")
    ElMessage.info("文件正在上传")
    // console.log(item.port)
    let res = await requestUtil.post(item.port + '/uploadImage', formData);
    if (res.data.code == "647") {
      // 手动调用上传成功接口
      handleImageSuccess(item, res.data,);
      cropper.value = null;
    } else {
      ElMessage.error("图片过大或操作失败")
    }
  } else if (fileListNumbyImage.value > 0 && !isUploadSuccess.value) {
    const firstElement = uploadImageRef.value[index];
    if (typeof firstElement === 'object' && firstElement !== null) {
      if (firstElement.hasOwnProperty('submit') && typeof firstElement.submit === 'function') {
        ElMessage.info("文件正在上传")
        firstElement.submit();
      }
    }
  } else {
    ElMessage.error("请先选择图片")
  }
}
// 上传图片成功 后预览状态 以及填充表单
const handleImageSuccess = (item, response, file, fileList) => {
  if (response) {
    // 遍历 allList 找到 atrType = image 并且是当前item 的并将它赋值
    props.allList.forEach((i, index) => {
      if (i.atrType === 'image' && i === item) {
        queryForm.value[`${i.prop}`] = response.title;
        // 查看大图做准备 也同时回显
        imageUrl.value[`${index}`] = getServerUrl() + response.src;
        imageKey.value[`${index}`] = imageUrl.value;
        // 查看大图准备链接
        prepareViewImage.value[index] = [getServerUrl() + response.src]
        return;
      }
    });
    // 是否发生上传事件
    isUploaded.value = true;
    ElMessage.success("图片上传成功,可预览图片!")
    fileListArrayByImage.value = []
    isUploadSuccess.value = true;
  } else {
    ElMessage.error("服务器异常,请重试!")
  }
}
///////////////////////↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ //////////////////////////////////////////////
///////////////////////Image Image Image Image Image Image Image Image Image //////////////////////////////////////////
///////////////////////Image Image Image Image Image Image Image Image Image ////////////////////////////////////////////////////////////////////////


///////////////////////↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓//////////////////////////////////////////////
///////////////////////Audio Audio Audio Audio Audio Audio Audio Audio Audio //////////////////////////////////////////
///////////////////////Audio Audio Audio Audio Audio Audio Audio Audio Audio ////////////////////////////////////////////////////////////////////////
const prepareAudioUrl = ref([])
const audioUrl = ref([])
const audioButton = ref([])
const audioVisible = ref([])
const uploadAudioRef = ref([])
const fileListArrayByAudio = ref([])
const audioRef = ref([])
const mutedAudio = ref(true)
const hasAudioType = ref(false) // 判断是否有视频上传标签
const fileListNumByAudio = ref(0)
const AudioSizeType = ref(currentUser && currentUser.username == 'C' && currentUser.roles.includes('超级管理员') ? 1024 : 50);
const audioRefKey = ref(0)
// 动态设置上传音频ref
const setUploadAudioRef = (index) => {
  return aR => {
    uploadAudioRef.value[index] = aR;
  };
};
// 动态设置上传音频ref
const setAudioRef = (index) => {
  return a => {
    audioRef.value[index] = a;
  };
};
// 检测表单是否含音频元素
const checkHasAudio = () => {
  props.allList.forEach(item => {
    if (item.atrType === 'audio') {
      hasAudioType.value = true;
      return;
    }
  });
}

// 关闭视频相关信息
const clearAudioMsg = () => {
  checkHasAudio()
  if (hasAudioType.value) {
    fileListArrayByAudio.value = []
    isUploadSuccess.value = false;
    fileListNumByAudio.value = 0;
    audioRefKey.value++;
    isUploaded.value = false;
    // 播放框存在
    if (audioRef.value) {
      // 关闭所有播放容器
      audioVisible.value = [];
      // 关闭所有播放操作按钮
      audioButton.value = [];
    }
  }
}

// 播放按钮
const playAudio = (index) => {
  audioUrl.value[`${index}`] = prepareAudioUrl.value[`${index}`];
  if (audioVisible.value[`${index}`] == false) {
    audioVisible.value[`${index}`] = true
  } else if (audioVisible.value[`${index}`] == true && audioRef.value[`${index}`]) {
    audioRef.value[`${index}`].play();
  }
}

// 暂停按钮
const pauseAudio = (index) => {
  if (audioVisible.value[`${index}`] == true) {
    audioRef.value[`${index}`].pause();
  }
}
// 关闭
const finishAudio = (index) => {
  if (audioVisible.value[`${index}`] == true) {
    audioVisible.value[`${index}`] = false
  }
}
// 视频声音
const audioAudio = (index) => {
  if (audioVisible.value[`${index}`] == true) {
    mutedAudio.value = !mutedAudio.value
  }
}

// 下载
const downloadAudio = async () => {
  // 获取视频资源
  const response = await fetch(audioUrl.value);
  const videoBlob = await response.blob();
  // 创建 Blob 对象并生成下载链接
  const blob = new Blob([videoBlob], {type: 'audio/mp3'});
  const url = window.URL.createObjectURL(blob);
  // 创建一个下载链接
  const a = document.createElement('a');
  a.href = url;
  let name = '下载资源'
  if (queryForm.value.audioName) {
    name = queryForm.value.audioName;
  }
  a.download = name + '.mp3'; // 设置下载文件的名称
  a.target = '_blank'; // 在新窗口中打开下载链接
  // 模拟点击下载链接
  document.body.appendChild(a);
  a.click();
  // 释放资源
  window.URL.revokeObjectURL(url);
  // 移除下载链接
  document.body.removeChild(a);
}

// 音频回显
const audioEcho = () => {
  if (props.dialogVisible == true && props.AddOrEditDialogStatus != 1) {
    props.allList.forEach((item, index) => {
      if (item.atrType == 'audio') {
        prepareAudioUrl.value[`${index}`] = getAudioUrl() + queryForm.value[`${item.prop}`]
        audioVisible.value[`${index}`] = true;
        audioButton.value[`${index}`] = true;
        // 调用播放
        playAudio(index);
      }
    });
  } else if (props.dialogVisible == true && props.AddOrEditDialogStatus == 1) {
    // 添加把显现按钮设置为false
    props.allList.forEach((item, index) => {
      if (item.atrType == 'audio') {
        audioVisible.value[`${index}`] = false;
        audioButton.value[`${index}`] = false;
      }
    });
  }
}

// 点击选择音频
const selectAudio = (index) => {
  if (uploadAudioRef.value[`${index}`]) {
    const select = uploadAudioRef.value[`${index}`].$el.querySelector('.el-upload__input');
    if (select) {
      select.click();
    }
  }
};

// 选择音频文件触发事件
const handleAudioFileChange = (file, fileList) => {
  if (currentUser && currentUser.username == 'C' && currentUser.roles.includes('超级管理员'))
    AudioSizeType.value = 1024;
  const isVideoFormatValid = ['mp3', 'flac'].includes(file.name.split('.').pop().toLowerCase());
  const isFileSizeValid = file.size / 1024 / 1024 <= AudioSizeType.value;
  if (!isVideoFormatValid || !isFileSizeValid) {
    ElMessage.error('只支持 [mp3、flac] 格式的音频文件，且不超过' + AudioSizeType.value + 'M')
    fileList.pop();
  }
  if (fileList.length > 1) {
    fileList[0] = fileList[1]
    fileList.pop();
  }
  fileListNumByAudio.value = fileList.length;
}

// 点击（上传音频）
const submitUploadAudio = async (item, index) => {
  if (fileListNumByAudio.value > 0 && !isUploadSuccess.value) {
    const firstElement = uploadAudioRef.value[`${index}`];
    if (typeof firstElement === 'object' && firstElement !== null) {
      if (firstElement.hasOwnProperty('submit') && typeof firstElement.submit === 'function') {
        ElMessage.info("文件正在上传")
        firstElement.submit();
      }
    }
  } else {
    ElMessage.error("请先选择视频")
  }
}
// 上传音频成功回调
const handleAudioSuccess = (item, response, file, fileList) => {
  if (response) {
    props.allList.forEach((i, index) => {
      if (i.atrType === 'audio' && i == item) {
        queryForm.value[`${i.prop}`] = response.title;
        prepareAudioUrl.value[`${index}`] = getAudioUrl() + response.title;
        audioButton.value[`${index}`] = true;
        finishAudio(index)
        nextTick(() => {
          audioUrl.value[`${index}`] = prepareAudioUrl.value[`${index}`];
          playAudio(index)
        })
      }
    });
    // 是否发生上传事件
    isUploaded.value = true;
    // 清理视频表单
    ElMessage.success("音频上传成功,可预览音频!")
    fileListNumByAudio.value = 0;
    isUploadSuccess.value = true;
  } else {
    ElMessage.error("服务器异常,请重试!")
  }
}

///////////////////////↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ //////////////////////////////////////////////
///////////////////////Audio Audio Audio Audio Audio Audio Audio Audio Audio //////////////////////////////////////////
///////////////////////Audio Audio Audio Audio Audio Audio Audio Audio Audio ////////////////////////////////////////////////////////////////////////


///////////////////////↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓ ↓//////////////////////////////////////////////
///////////////////////Video Video Video Video Video Video Video Video Video //////////////////////////////////////////
///////////////////////Video Video Video Video Video Video Video Video Video ////////////////////////////////////////////////////////////////////////
const placeholderImageUrl = ref(videoImage)
const fileListArrayByVideo = ref([]);
const videoUrl = ref([])
const prepareVideoUrl = ref([])
const videoButton = ref([])
const videoVisible = ref([])
const uploadVideoRef = ref([])
const videoRef = ref([])
const mutedVideo = ref(true)
const videoSizeType = ref(currentUser && currentUser.username == 'C' && currentUser.roles.includes('超级管理员') ? 1024 : 100);
const fileListNumByVideo = ref(0)
const videoRefKey = ref(0)
const hasVideoType = ref(false) // 判断是否有视频上传标签
// 动态设置上传视频ref
const setUploadVideoRef = (index) => {
  return videoUploadRef => {
    uploadVideoRef.value[index] = videoUploadRef;
  };
};
// 动态设置上传视频ref
const setVideoRef = (index) => {
  return v => {
    videoRef.value[index] = v;
  };
};
// 检测表单是否含视频元素
const checkHasVideo = () => {
  props.allList.forEach(item => {
    if (item.atrType === 'video') {
      hasVideoType.value = true;
      return;
    }
  });
}
// 关闭视频相关信息
const clearVideoMsg = () => {
  checkHasVideo()
  if (hasVideoType.value) {
    fileListArrayByVideo.value = []
    isUploadSuccess.value = false;
    fileListNumByVideo.value = 0;
    videoRefKey.value++;
    isUploaded.value = false;
    // 播放框存在
    if (videoRef.value) {
      // 关闭所有播放容器
      videoVisible.value = [];
      // 关闭所有播放操作按钮
      videoButton.value = [];
    }
  }
}
// 播放按钮
const playVideo = (index) => {
  videoUrl.value[`${index}`] = prepareVideoUrl.value[index];
  if (videoVisible.value[`${index}`] == false) {
    videoVisible.value[`${index}`] = true
  } else if (videoVisible.value[`${index}`] == true && videoRef.value[`${index}`]) {
    videoRef.value[`${index}`].play();
  }
}

// 暂停按钮
const pauseVideo = (index) => {
  if (videoVisible.value[`${index}`] == true) {
    videoRef.value[`${index}`].pause();
  }
}
// 关闭
const finishVideo = (index) => {
  if (videoVisible.value[`${index}`] == true) {
    videoVisible.value[`${index}`] = false
  }
}
// 视频声音
const audioVideo = (index) => {
  if (videoVisible.value[`${index}`] == true) {
    mutedVideo.value = !mutedVideo.value
  }
}
// 下载
const downloadVideo = async (index) => {
  // 获取视频资源
  const response = await fetch(videoUrl.value[`${index}`]);
  const videoBlob = await response.blob();
  // 创建 Blob 对象并生成下载链接
  const blob = new Blob([videoBlob], {type: 'video/mp4'});
  const url = window.URL.createObjectURL(blob);
  // 创建一个下载链接
  const a = document.createElement('a');
  a.href = url;
  let name = '下载资源'
  if (queryForm.value.videoName) {
    name = queryForm.value.videoName;
  }
  a.download = name + '.mp4'; // 设置下载文件的名称
  a.target = '_blank'; // 在新窗口中打开下载链接
  // 模拟点击下载链接
  document.body.appendChild(a);
  a.click();
  // 释放资源
  window.URL.revokeObjectURL(url);
  // 移除下载链接
  document.body.removeChild(a);
}

// 视频回显
const videoEcho = () => {
  if (props.dialogVisible == true && props.AddOrEditDialogStatus != 1) {
    props.allList.forEach((item, index) => {
      if (item.atrType == 'video') {
        prepareVideoUrl.value[index] = getVideoUrl() + queryForm.value[`${item.prop}`]
        videoVisible.value[`${index}`] = true;
        videoButton.value[`${index}`] = true;
        // 调用播放
        playVideo(index);
      }
    });
  } else if (props.dialogVisible == true && props.AddOrEditDialogStatus == 1) {
    // 添加把显现按钮设置为false
    props.allList.forEach((item, index) => {
      if (item.atrType == 'video') {
        videoVisible.value[`${index}`] = false;
        videoButton.value[`${index}`] = false;
      }
    });
  }
}

// 点击选择视频
const selectVideo = (index) => {
  if (uploadVideoRef.value[index]) {
    const select = uploadVideoRef.value[index].$el.querySelector('.el-upload__input');
    if (select) {
      select.click();
    }
  }
};

// 首次视频文件触发
const handleVideoFileChange = (file, fileList) => {
  if (file.response && file.response.code == 647) {
  } else {
    isUploadSuccess.value = false;
  }
  if (currentUser && currentUser.username == 'C' && currentUser.roles.includes('超级管理员'))
    videoSizeType.value = 1024;
  const isVideoFormatValid = ['mp4', 'avi', 'mov', 'hevc'].includes(file.name.split('.').pop().toLowerCase());
  const isFileSizeValid = file.size / 1024 / 1024 <= videoSizeType.value;
  if (!isVideoFormatValid || !isFileSizeValid) {
    ElMessage.error('只支持 [mp4、avi、mov、hevc] 格式的视频文件，且不超过' + videoSizeType.value + 'M')
    fileList.pop();
  }
  if (fileList.length > 1) {
    fileList[0] = fileList[1]
    fileList.pop();
  }
  fileListNumByVideo.value = fileList.length;
}

// 点击上传视频
const submitUploadVideo = async (item, index) => {
  if (fileListNumByVideo.value > 0 && !isUploadSuccess.value) {
    const firstElement = uploadVideoRef.value[index];
    if (typeof firstElement === 'object' && firstElement !== null) {
      if (firstElement.hasOwnProperty('submit') && typeof firstElement.submit === 'function') {
        ElMessage.info("文件正在上传")
        firstElement.submit();
      }
    }
  } else {
    ElMessage.error("请先选择视频")
  }
}
// 上传视频成功回调
const handleVideoSuccess = (item, response, file, fileList) => {
  if (response) {
    props.allList.forEach((i, index) => {
      if (i.atrType === 'video' && i == item) {
        queryForm.value[`${i.prop}`] = response.title;
        prepareVideoUrl.value[index] = getVideoUrl() + response.title;
        videoButton.value[`${index}`] = true;
        finishVideo(index)
        nextTick(() => {
          videoUrl.value[`${index}`] = prepareVideoUrl.value[index];
          playVideo(index)
        })
      }
    });
    // 是否发生上传事件
    isUploaded.value = true;
    // 清理视频表单
    ElMessage.success("视频上传成功,可预览视频!")
    fileListNumByVideo.value = 0;
    isUploadSuccess.value = true;
  } else {
    ElMessage.error("服务器异常,请重试!")
  }
}
///////////////////////↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ ↑ //////////////////////////////////////////////
///////////////////////Video Video Video Video Video Video Video Video Video //////////////////////////////////////////
///////////////////////Video Video Video Video Video Video Video Video Video ////////////////////////////////////////////////////////////////////////


///////////////////////system system system system system system system system //////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////system system system system system system system system //////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////system system system system system system system system //////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////system system system system system system system system //////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////system system system system system system system system //////////////////////////////////////////////////////////////////////////////////////////////////////
// 表单页面 检测下拉栏并检测该页面权限(包含返回类型字符串，不包含返回false)
const formatVIFCheckBasicAtrType = (item) => {
  if (item.atrType == 'text' || item.atrType == 'textarea' || item.atrType == 'editor' || item.atrType == 'sort'
      || item.atrType == 'number' || item.atrType == 'select') {
    return commonCheck(item)
  }// 时间类型判断  1排除添加 2排除另存为
  else if ((item.atrType == 'none' || item.atrType == 'time'
      && (props.id.length > 0 || props.id > 0 || props.AddOrEditDialogStatus != 1))) {
    if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus == 3) return false;
    else return commonCheck(item)
  } else
    return false;
}
// 调用公共判断（添加/编辑）页面返回信息
const commonCheck = (item) => {
  if (props.id > 0 || props.id.length > 0) {  // 编辑
    if (item.atrDisplay != null) {
      if (item.atrDisplay.includes("edit")) return item.atrType;
      else return false;
    } else return false;
  } else if (props.id <= 0 || props.id.length <= 0) { // 添加
    if (item.atrDisplay != null) {
      if (item.atrDisplay.includes("add")) return item.atrType;
      else return false;
    } else return false;
  }
}
// 动态生成规则
const rules = computed(() => {
  const ruleObj = ref({});
  props.allList.forEach(item => {
    if (item.required === 'true') {
      ruleObj.value[item.prop] = [
        {required: true, message: `${item.label}不能为空`, trigger: 'blur'},
        // {min: 1, max: 12, message: '长度为 1 到 12 个字符'}
      ];
    }
  });
  return ruleObj.value;
});
const SelectDictTypeValue = ref(true) // 数据字典判断

// 大型判断
// 规则1 判断input类型 如果是select 数字字典才会解除  规则2 只读判断
const formatCheckReadOnlyAndSelectChangeDictType = (item) => {
  // 规则1
  const keys = Object.keys(queryForm.value);
  if (keys.includes('atrType')) {
    // 判断input类型 如果是select 数字字典才会解除
    if (queryForm.value.atrType == 'select') {
      SelectDictTypeValue.value = false;
    } else {
      SelectDictTypeValue.value = true;
      // 查询是否有 dictType ,有的话将他清空
      if (keys.includes('dictType')) {
        queryForm.value.dictType = null;
      }
    }
  } else {
    SelectDictTypeValue.value = true;
  }
  // 另存为优先判断 另存为全部解除 且id清零
  if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus == 3) {
    // 另存为
    return false;
  } else if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus == 4) {
    // 查看
    SelectDictTypeValue.value = true;
    return true;
  } else {
    // 规则2 只读判断
    if (item.readOnly == 'true') {
      return true;
    } else if (item.readOnly == 'editTrue') {
      if (props.id.length > 0 || props.id > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
const queryForm = ref({})
const formRef = ref(null)
//定义父组件方法 子级可以直接调用
const emits = defineEmits(['update:modelValue', 'initTableList'])

// 关闭
const handleClose = () => {
  // 关闭视频相关信息
  clearImageMsg();
  clearVideoMsg();
  clearAudioMsg();
  clearEditor();
  //重置表单验证规则红色文字
  formRef.value.resetFields();
  // 卸载监听
  window.removeEventListener('keypress', handleKeyPress);
  // 清空上传表单
  emits('update:modelValue', false) // modelValue 的值是父组件dialog框的model值  即关闭弹框
}

// 自定义规则=》多选页面时候，选无页面立即清空其他选择页面
// （多选点击事件）多选下拉数组转字符串  存的也是临时值，提交后端字段就是字符串。
const formatArrayToStringAndCheckContradiction = (value, item) => {
  // 当前未选择无页面时，选择了无页面 (之前没选过none ，当前选择的）
  if (value.includes('none') && value[0] != 'none') {
    // 表单变成只有无页面的
    atrDisplayString.value = "none";
    // 回显
    queryForm.value[`${item.prop}`] = atrDisplayString.value.split('C').map(item => item.replace('C', ','));
  } else if (value.includes('none') && value[0] == 'none') {
    // 当前已选择无页面时，选择了其他有页面时 清空无页面 添加新的 (之前选过none）
    atrDisplayString.value = value.join(",").replace(/,/g, "C");
    atrDisplayString.value = atrDisplayString.value.replace(/noneC/g, '') // 加C是默认的
    queryForm.value[`${item.prop}`] = atrDisplayString.value.split('C').map(item => item.replace('C', ','));
    // 回显
  } else if (value.length == 0) {
    // 原地取消
    atrDisplayString.value = "";
  } else {
    atrDisplayString.value = value.join(",").replace(/,/g, "C");
  }
  // console.log(value)
  // console.log(atrDisplayString.value)
}

const atrDisplayString = ref("")

// 提交表单前判断  有值则转设置多选值 atrDisplay ,value = atrDisplayString.value
const setAtrDisplayString = (value) => {
  const keys = Object.keys(queryForm.value);
  // 有表单就赋值，atrDisplayString.value 一直会存在一个值
  if (keys.includes('atrDisplay')) {
    queryForm.value.atrDisplay = value;
  }
}


// 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据
// 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据
// 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据
// 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据 回显数据
const confimType = ref(false)  // true 有确认 false 没有确认按钮
const initQueryFormData = async (id) => {
  if (id) {
    let result = await requestUtil.get(props.port + "/" + id);
    // console.log(result)
    queryForm.value = result.data.entity;
    // 多选还原数组 (专门为了属性表的多选页面设计)
    if (queryForm.value.atrDisplay != null) {
      // 先存一份一会如果没发生改变初始提交的
      atrDisplayString.value = queryForm.value.atrDisplay;
      // 初始化转变 字符串为数组数据
      queryForm.value.atrDisplay = queryForm.value.atrDisplay.split('C').map(item => item.replace('C', ','));
    }
  }
  // 图片回显(选择性回显）
  imageEcho();
  audioEcho();
  videoEcho();
  // 判断 是否另存为
  if (props.AddOrEditDialogStatus == 3) {
    // 遍历allList的atrDisplay页面是否有add,有的话遍历queryForm清空对应的值.
    const temporarilyForm = queryForm.value
    queryForm.value = {}
    props.allList.forEach(item => {
      if (item.atrDisplay != null && item.atrDisplay.includes('add')) {
        // 回显表有需要add的页面 就将临时表的对应的内容复制到 queryForm
        if (temporarilyForm[`${item.prop}`]) {
          queryForm.value[`${item.prop}`] = temporarilyForm[`${item.prop}`];
        }
      }
    });
    // console.log(queryForm.value)
  }
  // 最少加载时间
  timeId = setTimeout(function () {
    loading.value = false;
  }, 500)
}

// 提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定
// 提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定
// 提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定
// 提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定
// 提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定提交/确定

let timeId = ref();
const handleConfirm = () => {
  setAtrDisplayString(atrDisplayString.value);
  formRef.value.validate(async (valid) => {
    checkHasImage();
    checkHasVideo();
    checkHasAudio();
    if (!hasVideoType.value && !hasAudioType.value && !hasImageType.value) {
      isUploadSuccess.value = true;
    } else if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus != 1) {
      // 非添加 没发生过上传
      if (!isUploaded.value)
        isUploadSuccess.value = true;
    }
    if (valid && isUploadSuccess.value) {
      // 传回当前登录用户id
      queryForm.value.userId = currentUser.id;
      let result = await requestUtil.post(props.port + '/save', queryForm.value);
      if (result.data.code == 647) {
        ElMessage.success("成功")
        formRef.value.resetFields(); //重置表单
        emits("initTableList")  //调用父页面传过来的初始化刷新表单
        handleClose();
      } else {
        ElMessage.error(result.data.msg);
        handleClose();
      }
    } else {
      if (hasImageType.value || hasVideoType.value || hasAudioType.value) {
        if (hasVideoType.value && !isUploadSuccess.value) {
          ElMessage.error("您还有待上传的视频文件")
        } else if (hasAudioType.value && !isUploadSuccess.value) {
          ElMessage.error("您还有待上传的音频文件")
        } else if (hasImageType.value && !isUploadSuccess.value) {
          ElMessage.error("您还有待上传的图片文件")
        }
      } else {
        ElMessage.error("请将信息补充完整")
      }
    }
  })
}

////检测弹窗是否打开 检测弹窗是否打开 检测弹窗是否打开 检测弹窗是否打开/////////////////////////////////////////////////////////////////////////////////////////////////////////////
////检测弹窗是否打开 检测弹窗是否打开 检测弹窗是否打开 检测弹窗是否打开/////////////////////////////////////////////////////////////////////////////////////////////////////////////
////检测弹窗是否打开 检测弹窗是否打开 检测弹窗是否打开 检测弹窗是否打开/////////////////////////////////////////////////////////////////////////////////////////////////////////////
watch(
    () => [props.dialogVisible],
    async () => {
      if (props.dialogVisible == true) {
        // 键盘回车监听
        window.addEventListener('keypress', handleKeyPress);
        loading.value = true;
        // 2修改 / 3另存为 /4查看
        if (props.id > 0 || props.id.length > 0) {
          // 1添加 2编辑 3另存为 4查看
          if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus == 2) {
            // 编辑
            await initQueryFormData(props.id);
            confimType.value = true;
          } else if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus == 3) {
            // 另存为
            await initQueryFormData(props.id);
            confimType.value = true;
          } else if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus == 4) {
            // 查看
            await initQueryFormData(props.id);
            confimType.value = false;
          }
        } else if (props.AddOrEditDialogStatus && props.AddOrEditDialogStatus == 1) {
          // 1添加 重置表单数据
          await initQueryFormData();
          queryForm.value = {}
          confimType.value = true;
        }
        // 最少加载时间 回显里设置过 此处并不做效
        timeId = setTimeout(function () {
          loading.value = false;
        }, 1000)
      }
    }
)

const lastKeyPressTime = ref(0)
let timeIdEnter = ref()
// 监听回车提交事件
const handleKeyPress = (event) => {
  if (event.key === "Enter" && (props.AddOrEditDialogStatus == 1 || props.AddOrEditDialogStatus == 2 || props.AddOrEditDialogStatus == 3)) {
    // console.log(lastKeyPressTime.value)
    if (lastKeyPressTime.value === 0) {
      ElMessage.success("双击回车即可提交!")
    }
    const currentTime = Date.now();
    if (currentTime - lastKeyPressTime.value <= 1000) {
      handleConfirm();
      // window.removeEventListener('keypress', handleKeyPress);
    }
    lastKeyPressTime.value = currentTime;
    clearTimeout(timeIdEnter)
    timeIdEnter = setTimeout(function () {
      lastKeyPressTime.value = 0;
    }, 3000)
  }
};
onMounted(async () => {
  // 组件挂载后，添加全局点击事件监听器
  document.addEventListener('click', checkClickClearPreviewImage);
  headers.value.token = await decryptData(sessionStorage.getItem("token"))
});

onUnmounted(() => {
  // 组件卸载前，移除事件监听器
  document.removeEventListener('click', checkClickClearPreviewImage);
});

onBeforeUnmount(() => {
});
const dialogTitle = () => {
  switch (props.AddOrEditDialogStatus) {
    case 1:
      return props.dialogTitle + ' 🚩 添加'
      break;
    case 2:
      return props.dialogTitle + ' 🚩 编辑'
      break;
    case 3:
      return props.dialogTitle + ' 🚩 另存为'
      break;
    default:
      return props.dialogTitle + ' 🚩 查看'
  }
}
const screenWidth = ref(0);
// 传递dialog的宽度
const setDialogWidth = () => {
  screenWidth.value = window.innerWidth;
  // console.log('当前屏幕宽度变为: ' + screenWidth.value);
  if (screenWidth.value < 800) {
    cropperDialogWidth.value = "90%";
    cropperEditorDialogWidth.value = "90%";
  } else if (screenWidth.value >= 800) {
    cropperDialogWidth.value = "90%";
    cropperEditorDialogWidth.value = "90%";
  }
}
//父组件传过来三个参数
const props = defineProps(
    {
      id: {
        type: String,
        default: -1,
        required: true
      },
      AddOrEditDialogStatus: {
        type: Number,
        default: -1,
        required: true
      },
      dialogTitle: {
        type: String,
        default: "",
        required: true
      },
      dialogVisible: {
        type: Boolean,
        default: false,
        required: true
      },
      elDialogWidth: {
        type: String,
        default: '70%',
        required: true
      },
      allList: {
        type: Array,
        default: [],
        required: true,
      },
      port: {
        type: String,
        default: "",
      },
      tableMessage: {
        type: Object,
        required: true
      },
    }
)


</script>

<style lang="scss" scoped>
////////image image image image image  ///////////////////////////////////////////////////////////
////////image image image image image  ///////////////////////////////////////////////////////////
////////image image image image image  ///////////////////////////////////////////////////////////
// 图片懒加载
.lazyImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageItem {
  // 图片上传
  .imageUploader {
    width: 100% !important;

    :deep .el-upload-dragger {
      width: 100%;
      height: 100%;
      max-height: 160px;
      max-width: 160px;
      padding: 0px !important;
      border: 0px !important;
      display: flex;
      //图片
      .imageOutDiv {
        width: 100%;
        height: 100%;
        max-height: 160px;
        max-width: 160px;
        display: flex;
        justify-content: center;
        align-items: center;

        .avatarImg {
          width: 100%;
          height: 100%;
          //border: 1px solid red;
          //background-image: url("../../src/assets/img/imageLoading.png");
          .el-image__inner {
            max-height: 160px;
            max-width: 160px;
          }
        }
      }
    }
  }

  // 图片上传按钮菜单
  .menu {
    white-space: normal;
    margin: 10px 10px 10px 0px !important;

    .menuButton {
      margin: 10px 10px 10px 10px;
      white-space: normal;
    }
  }
}

////////audio audio audio audio audio  ///////////////////////////////////////////////////////////
////////audio audio audio audio audio  ///////////////////////////////////////////////////////////
////////audio audio audio audio audio  ///////////////////////////////////////////////////////////

.audioItem {
  .audioUploader {

    // 为占item_content的百分比
    width: 100% !important;

    :deep .el-upload-dragger {
      width: 100%;
      height: 100%;
      padding: 0px !important;
      border: 0px !important;
      display: flex;

      .audio {
        width: 100% !important;
        padding: 0px !important;
        border: 0px !important;
      }
    }

    .title {
      padding: 10px;
      margin: auto;

      .title_tip {
        color: red !important;
      }
    }

  }

  .right {
    // 音频
    .audioButton {
      .audioControllerButton {
        color: red;
        box-shadow: 2px 2px #cfeae5;
        border-radius: 5px;
        padding: 5px;
        margin: 10px;
        background-color: var(--hover-color-theme-white);
        white-space: nowrap;

        &:hover {
          background-color: rgba(213, 244, 200);
        }
      }
    }
  }
}

////////视频 视频 视频 视频 视频 视频 视频   ///////////////////////////////////////////////////////////
////////视频 视频 视频 视频 视频 视频 视频   ///////////////////////////////////////////////////////////
////////视频 视频 视频 视频 视频 视频 视频   ///////////////////////////////////////////////////////////
.videoItem {
  // 右侧整体大框
  .videoUploader {
    // 为占item_content的百分比
    width: 100%;
    // 视频外框
    :deep .el-upload-dragger {
      //background-color: var(--active-background-color-theme-white);
      width: 100%;
      height: 100%;
      padding: 0px !important;
      border: 0px !important;
      display: flex;

      // 视频框
      .video {
        width: 100% !important;
        height: 100% !important;
        padding: 0px !important;
        border: 0px !important;
      }
    }

    // 提示文字
    .title {
      margin: auto;
      line-height: 29px !important;

      .title_button {
        margin: 0px !important;
        padding: 0px !important;
      }

      .title_tip {
        color: red !important;
      }
    }


  }

  .right {
    height: auto;
    white-space: normal;

    .videoButton {
      .videoControllerButton {
        color: red;
        box-shadow: 2px 2px #cfeae5;
        border-radius: 5px;
        padding: 5px;
        margin: 10px;
        background-color: var(--hover-color-theme-white);
        white-space: nowrap;

        &:hover {
          background-color: rgba(213, 244, 200);
        }
      }
    }
  }
}


.menuButton {
  margin: 10px 10px 10px 10px;
  white-space: normal;
}


// 时间
:deep .row_input_time {
  font-size: 16px;
  width: 200px !important;
  // 白主题
  .el-input__wrapper {
    background-color: white;
    // 字体颜色
    .el-input__inner {
      color: var(--text-color-theme-white);
    }
  }
}

// 表单
.dialog {
  background-color: red;
  --el-dialog-bg-color: red !important;
  --el-dialog-title-font-size: 1px !important;
  min-width: 300px !important;
  max-height: 400px !important;
  // 表单
  .form {
    max-height: 90%;

    .formDiv {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  // 底部确认菜单
  .dialog-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}


// 表单 非图片 视频 音频 富文本
.el-form-item {
  //border: 1px solid red;
  margin: 5px !important;
  width: 100% !important;
  background-color: var(--active-background-color-theme-white);
  box-shadow: 2px 2px #ccc;
  padding: 5px;
  border-radius: 5px;
}

// 表单左侧
:deep .el-form-item__label {
  //border: 1px solid green;
  margin: 5px 0px !important;
  width: 33% !important;
  height: auto;
}

// 表单右侧
:deep .el-form-item__content {
  margin: 5px 0px !important;
  width: 67% !important;
  height: auto;
}

// input 其他 非视频
.row_input {
  margin: 5px 0px !important;
  width: 100% !important;
}

// 富文本
:deep .editorUploader {
  width: 67% !important;
  height: 100%;
  padding: 0px !important;
}

//row_input ql-container ql-snow
:deep .ql-container {
  width: 100% !important;
  height: 200px !important;
  padding: 5px !important;

  .ql-editor {
    padding: 5px !important;
    width: 100% !important;
    height: 100% !important;

    .img {
      width: 20px !important;
    }
  }
}
</style>
